import type { L2DepositsItem } from 'types/api/l2Deposits';
import type { L2OutputRootsItem } from 'types/api/l2OutputRoots';
import type { L2TxnBatchesItem } from 'types/api/l2TxnBatches';
import type { L2WithdrawalsItem } from 'types/api/l2Withdrawals';
import type { SequencerBatchItem, SequencerItem } from 'types/api/Sequencer';

import { ADDRESS_HASH } from './addressParams';
import { BLOCK } from './block';
import { TX_HASH } from './tx';
import { OptimisticL2TxnBatchesItem } from 'types/api/optimisticL2';

export const L2_DEPOSIT_ITEM: L2DepositsItem = {
  blockNumber: 9045233,
  blockTimestamp: '2023-05-22T18:00:36.000000Z',
  targetTxHash: TX_HASH,
  from: ADDRESS_HASH,
  amount: '100000',
  transactionHash: TX_HASH,
};

export const L2_WITHDRAWAL_ITEM: L2WithdrawalsItem = {
  // challenge_period_end: null,
  // msg_nonce_version: 1,
  from: ADDRESS_HASH,
  //bedrockProvenTxHash: TX_HASH,
  // bedrockWithdrawalHash: TX_HASH,
  // bedrockFinalizedTxHash: TX_HASH,
  blockTimestamp: '2023-06-01T13:44:56.000000Z',
  transactionHash: TX_HASH,
  withdrawalNonce: 0,
  amount: '100000',
  status: 'Ready to claim',
  timeLeft: "100",
};

export const OP_L2_TXN_BATCHES_ITEM: OptimisticL2TxnBatchesItem = {
  l1_timestamp: '2023-06-01T14:46:48.000000Z',
  l1_tx_hashes: [
    TX_HASH,
  ],
  l2_block_number: 5218590,
  tx_count: 9,
};

export const L2_TXN_BATCHES_ITEM: L2TxnBatchesItem = {
  BatchIndex: 433,
  Timestamp: '1697004451',
  L1TxHash: TX_HASH,
  L1BlockNumber: 84275,
  L2TxCount: 138,
  L2BlockCount: 559,
  L2BlockNumber: 63752,
  StartBlock: 100,
  EndBlock: 238,
};

export const SEQUENCER_ITEM: SequencerItem = {
  id: 1,
  name: 'local',
  address: ADDRESS_HASH,
  block_count: 10102468,
  status: 1,
  straked: '',
  updated_number: 30990,
};

export const L2_OUTPUT_ROOTS_ITEM: L2OutputRootsItem = {
  l1_block_number: 9103684,
  l1_timestamp: '2023-06-01T15:26:12.000000Z',
  l1_tx_hash: TX_HASH,
  l2_block_number: 10102468,
  l2_output_index: 50655,
  output_root: TX_HASH,
};

export const SEQUENCER_BATCH_ITEM: SequencerBatchItem = BLOCK;
