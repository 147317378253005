import TxEntity from "ui/shared/entities/tx/TxEntity"

import chain from 'configs/app/chain';
import React from "react";
import { useBlock } from "wagmi";


export const TxEntityReceipt = ({
  isLoading,
  targetTxHash,
  item,
  ...props
}: {
  isLoading: boolean | undefined;
  targetTxHash: string;
  [key: string]: any;
}) => {
  const { data: result } = useBlock({
    chainId: Number(chain.l1Id),
    blockTag: 'finalized',
    query: {
      refetchInterval: 60 * 1000
    }
  });

  return <>
    <TxEntity
      isLoading={isLoading}
      hash={targetTxHash}
      {...props}
      noLink={(!Number(item.blockNumber) || !Number(result?.number) || Number(item.blockNumber) > Number(result?.number))}
      noCopy={!(!Number(item.blockNumber) || !Number(result?.number) || Number(item.blockNumber) > Number(result?.number))}
    />
  </>
}

function arePropsEqual(oldProps: any, newProps: any) {
  return (
    oldProps.targetTxHash === newProps.targetTxHash &&
    oldProps.isLoading === newProps.isLoading &&
    oldProps.blockTimestamp === newProps.blockTimestamp
  );
}

export default React.memo(TxEntityReceipt, arePropsEqual);