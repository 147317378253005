import { Grid } from '@chakra-ui/react';
import React from 'react';

import config from 'configs/app';
import useApiQuery from 'lib/api/useApiQuery';
import { HOMEPAGE_STATS } from 'stubs/stats';
import GasInfoTooltip from 'ui/shared/gas/GasInfoTooltip';
import GasPrice from 'ui/shared/gas/GasPrice';
import IconSvg from 'ui/shared/IconSvg';
import StatsWidget from 'ui/shared/stats/StatsWidget';

const hasAvgBlockTime = config.UI.homepage.showAvgBlockTime;
const rollupFeature = config.features.rollup;

const Stats = () => {
  const [ hasGasTracker, setHasGasTracker ] = React.useState(config.features.gasTracker.isEnabled);
  const { data, isPlaceholderData, isError, dataUpdatedAt } = useApiQuery('homepage_stats', {
    queryOptions: {
      refetchOnMount: false,
      placeholderData: HOMEPAGE_STATS,
    },
  });

  React.useEffect(() => {
    if (!isPlaceholderData && !data?.gas_prices?.average) {
      setHasGasTracker(false);
    }
  // should run only after initial fetch
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ isPlaceholderData ]);

  // const zkEvmLatestBatchQuery = useApiQuery('homepage_zkevm_latest_batch', {
  //   queryOptions: {
  //     placeholderData: 12345,
  //     enabled: rollupFeature.isEnabled && rollupFeature.type === 'zkEvm',
  //   },
  // });

  // const zkSyncLatestBatchQuery = useApiQuery('homepage_zksync_latest_batch', {
  //   queryOptions: {
  //     placeholderData: 12345,
  //     enabled: rollupFeature.isEnabled && rollupFeature.type === 'zkSync',
  //   },
  // });

  if (isError) { // || zkEvmLatestBatchQuery.isError || zkSyncLatestBatchQuery.isError) {
    return null;
  }

  const isLoading = isPlaceholderData;
    // (rollupFeature.isEnabled && rollupFeature.type === 'zkEvm' && zkEvmLatestBatchQuery.isPlaceholderData) ||
    // (rollupFeature.isEnabled && rollupFeature.type === 'zkSync' && zkSyncLatestBatchQuery.isPlaceholderData);

  let content;

  const lastItemStyle = { gridColumn: 'span 2' };

  let itemsCount = 5;
  !hasGasTracker && itemsCount--;
  !hasAvgBlockTime && itemsCount--;

  if (data) {
    !data.gas_prices && itemsCount--;
    data.rootstock_locked_btc && itemsCount++;
    rollupFeature.isEnabled && data.last_output_root_size && itemsCount++;
    const isOdd = Boolean(itemsCount % 2);
    const gasInfoTooltip = hasGasTracker && data.gas_prices && data.gas_prices.average ? (
      <GasInfoTooltip data={ data } dataUpdatedAt={ dataUpdatedAt }>
        <IconSvg
          isLoading={ isLoading }
          name="info"
          boxSize={ 5 }
          flexShrink={ 0 }
          cursor="pointer"
          color="icon_info"
          _hover={{ color: 'link_hovered' }}
        />
      </GasInfoTooltip>
    ) : null;

    content = (
      <>
        <StatsWidget
          icon="morph/blocks"
          flex="1"
          label="Total blocks"
          value={ Number(data.total_blocks).toLocaleString() }
          href={{ pathname: '/blocks' }}
          isLoading={ isLoading }
        />
        { hasAvgBlockTime && (
          <StatsWidget
            icon="morph/clock"
            flex="1"
            label="Average block time"
            value={ `${ (data.average_block_time / 1000).toFixed(1) }s` }
            isLoading={ isLoading }
          />
        ) }
        <StatsWidget
          icon="morph/transactions"
          flex="1"
          label="Total transactions"
          value={ Number(data.total_transactions).toLocaleString() }
          href={{ pathname: '/txs' }}
          isLoading={ isLoading }
        />
        <StatsWidget
          icon="morph/wallet"
          flex="1"
          label="Wallet addresses"
          value={ Number(data.total_addresses).toLocaleString() }
          isLoading={ isLoading }
          _last={ isOdd ? lastItemStyle : undefined }
        />
        { hasGasTracker && data.gas_prices && (
          <StatsWidget
            icon="morph/gas_pump"
            flex="1"
            label="Gas tracker"
            value={ data.gas_prices.average ? <GasPrice data={ data.gas_prices.average }/> : 'N/A' }
            hint={ gasInfoTooltip }
            isLoading={ isLoading }
            _last={ isOdd ? lastItemStyle : undefined }
          />
        ) }
      </>
    );
  }

  return (
    <Grid
      gridTemplateColumns={{ lg: `repeat(${ itemsCount }, 1fr)`, base: '1fr 1fr' }}
      // gridGap={{ base: 1, lg: 2 }}
      // flexBasis="50%"
      // flexGrow={ 1 }
      gridTemplateRows={{ lg: 'none', base: undefined }}
      gridGap="10px"
      marginTop="24px"
      display={{ lg: 'flex', base: 'grid' }}
    >
      { content }
    </Grid>

  );
};

export default Stats;
